'use client';
import CategoryTag from '@/components/tag/category-tag';
import { memo, useEffect, useState } from 'react';
import ThemeLink from '@/components/link';
import { GoodsCategoriesItemInterface } from '@/lib/type/goods-categories.type';
import { HomeDataProductInterface } from '@/lib/type/home-data.type';
import { Placeholder } from '@/components/placeholder';
import {
  getProductCenterLink,
  getProductCenterSlugLink,
  getProductDetailLink,
  getProductLink,
} from '@/lib/util/get-router';

type Props = GoodsCategoriesItemInterface;
export default memo(function GoodsCategoryProductGrid(props: Props) {
  const [cate, setCate] = useState<GoodsCategoriesItemInterface>(props);
  const [activeTagId, setActiveTagId] = useState(props.child[0].id);
  const [products, setProducts] = useState<HomeDataProductInterface[]>(
    props.child[0].products || []
  );
  const onTagClick = (id: string) => {
    if (id !== activeTagId) {
      setActiveTagId(id);
      setProducts(props.child.find((item) => item.id === id)?.products || []);
    }
  };
  useEffect(() => {
    if (props) {
      setCate(() => {
        const newCate = JSON.parse(
          JSON.stringify(props)
        ) as GoodsCategoriesItemInterface;

        newCate.child.sort((a, b) => +a.sort - +b.sort);

        onTagClick(newCate.child[0].id);
        return newCate;
      });
    }
  }, [props]);
  return (
    <>
      <div className="b-flex p-4 bg-white">
        <div>
          <p className="text-[20px] font-bold text-main">{cate.title}</p>
          <p className="text-themeP text-sm mt-1">{cate.desc}</p>
        </div>
        <ul className="e-flex gap-x-2 font-bold">
          {cate.child.slice(0, 3).map((item: any) => {
            return (
              <li key={item.id}>
                <CategoryTag
                  id={item.id}
                  name={item.title}
                  active={item.id === activeTagId}
                  onClick={onTagClick}
                />
              </li>
            );
          })}
          <li>
            <ThemeLink
              href={getProductCenterSlugLink(cate.pid, cate.id)}
              className="!text-[12px]"
            >
              更多
              <i className="ri-arrow-right-s-line"></i>
            </ThemeLink>
          </li>
        </ul>
      </div>
      <ul className="mt-2 grid grid-cols-5 gap-3">
        {products?.slice(0, 10).map((item) => {
          return (
            <li key={item.id} className="bg-white px-4 py-5 group">
              <ThemeLink href={getProductDetailLink(item.slug)}>
                <div className="c-flex">
                  <Placeholder
                    src={item.picture}
                    imageWidth={400}
                    imageHeight={400}
                    fit="contain"
                  ></Placeholder>
                </div>
                <p className="mt-3 group-hover:text-main text-center text-black text-sm">
                  {item.name}
                </p>
              </ThemeLink>
            </li>
          );
        })}
      </ul>
    </>
  );
});
