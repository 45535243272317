"use client";
import {Placeholder} from "@/components/placeholder";
import CategoryTag from "@/components/tag/category-tag";
import {useState} from "react";
import ThemeLink from "@/components/link";
import {HomeDataProductInterface} from "@/lib/type/home-data.type";
import {getProductDetailLink} from "@/lib/util/get-router";

type Props = {
    product_hot: HomeDataProductInterface[];
    product_recommend: HomeDataProductInterface[];
    product_new: HomeDataProductInterface[];
}

export default function Recommendation({product_hot, product_recommend, product_new}: Props) {
    const categorys = [{
        title: "热卖",
        id: "1",
        products: product_hot
    }, {
        title: "推荐",
        id: "2",
        products: product_recommend
    }, {
        title: "新品",
        id: "3",
        products: product_new
    }];
    const [activeTagId, setActiveTagId] = useState(categorys[0].id);
    const [products, setProducts] = useState(categorys[0].products);
    const onTagClick = (id: string) => {
        if (activeTagId !== id) {
            setActiveTagId(id);
            setProducts(categorys.find(item => item.id === id)?.products || []);
        }
    };
    return <div className="container select-none">
        <div className="bg-white py-8 px-7">
            <div className="b-flex mb-8">
                <p className="s-flex text-main font-bold gap-x-2 text-[32px] italic">
                    <span>推荐榜</span>
                    <Placeholder src="/site/tjbjt.png" imageWidth={45} imageHeight={40}/>
                </p>
                <ul className="e-flex gap-x-2 font-bold">
                    {
                        categorys.map(item => {
                            return <li key={item.id}>
                                <CategoryTag active={activeTagId === item.id} onClick={onTagClick} id={item.id}
                                             name={item.title}/>
                            </li>;
                        })
                    }
                </ul>
            </div>
            <div className="b-flex gap-x-8 items-start">
                <div className="w-[30%] flex-shrink-0 flex items-start">
                    <Placeholder src="/site/tjbbanner_07.webp" imageWidth={450} imageHeight={764}/>
                </div>
                <ul className="flex-1 flex-shrink-0 grid grid-cols-3 gap-6">
                    {
                        products.slice(0, 6).map((item, index) => {
                            return <li key={index} className="group relative">
                                <ThemeLink className="bg-mainBg px-3 py-3 c-flex flex-col"
                                           href={getProductDetailLink(item.slug)}>
                                    <div className="">
                                        <Placeholder src={item.picture} imageWidth={300} imageHeight={300}></Placeholder>
                                        {/*<img src={item.picture} alt={item.name} className="w-full h-full"/>*/}
                                    </div>
                                    <p className="mt-4 text-black group-hover:text-main duration-300 line-clamp-1">{item.name}</p>
                                </ThemeLink>
                            </li>;
                        })
                    }
                </ul>
            </div>
        </div>
    </div>;
}
