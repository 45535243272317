'use client';
import { useUserStore } from '@/lib/store/user.store';
import { useEffect, useState } from 'react';
import { UserInfoInterface } from '@/lib/type/login-register.type';
import { Avatar } from 'antd';

export default function UserAvatar({
  size = 40,
  children,
}: {
  size: number;
  children?: React.ReactNode;
}) {
  const { userInfo } = useUserStore();
  const [user, setUser] = useState<UserInfoInterface | null>(userInfo);
  useEffect(() => {
    setUser(userInfo);
  }, [userInfo]);
  return (
    <div className="w-fit h-fit rounded-full relative">
      {!user?.member?.head_portrait ? (
        <Avatar
          src={'/image/default_user_portrait_1554775283.gif'}
          size={size}
        />
      ) : (
        <Avatar src={user.member.head_portrait} size={size} />
      )}
      {children && children}
    </div>
  );
}
