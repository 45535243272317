"use client";
import {Button, Tag} from "antd";
import ThemeLink from "@/components/link";
import clsx from "clsx";
import {getCenterLink, getLoginLink, getRegisterLink} from "@/lib/util/get-router";
import {useUserStore} from "@/lib/store/user.store";
import {Fragment, useEffect, useState} from "react";
import {UserInfoInterface} from "@/lib/type/login-register.type";
import {useAuth} from "@/hooks/useAuth";
import {HomeDataAnnounceInterface} from "@/lib/type/home-data.type";
import UserAvatar from "@/components/user-avatar";

type Props = {
    announce: HomeDataAnnounceInterface[]
}

function User() {
    const {userInfo} = useUserStore();
    const {logout} = useAuth();
    const [user, setUser] = useState<UserInfoInterface | null>(userInfo);
    useEffect(() => {
        setUser(userInfo);
    }, [userInfo]);
    return <div>
        <div className="s-flex gap-x-2">
            <UserAvatar size={40}/>
            <div>
                <ThemeLink href={getCenterLink()}
                           className="!text-[#333] duration-300 hover:!text-main font-bold text-lg line-clamp-1">{
                    !!user?.member?.nickname ? user.member.nickname : "您好！"
                }</ThemeLink>
                <p className="text-[12px] text-themeP">欢迎光临{process.env.NEXT_PUBLIC_COMPANY_NAME}</p>
            </div>
        </div>
        <div className="grid grid-cols-2 gap-x-5 mt-4">
            {
                user ? <Fragment>
                    <Button type="primary" className="!rounded-[30px]" onClick={logout}>
                        退出登录
                    </Button>
                    <Button type="primary" className="!rounded-[30px] !bg-[#ff5432] hover:opacity-80"
                            href={getCenterLink()}>
                        个人中心
                    </Button>
                </Fragment> : <Fragment>
                    <Button type="primary" className="!rounded-[30px]" href={getLoginLink()}>
                        登录
                    </Button>
                    <Button type="primary" className="!rounded-[30px] !bg-[#ff5432] hover:opacity-80"
                            href={getRegisterLink()}>
                        注册
                    </Button>
                </Fragment>
            }
        </div>
    </div>;
}

function Order() {
    const cardList = [
        {
            icon: "ri-hourglass-fill",
            link: "/",
            title: "待报价"
        }, {
            icon: "ri-file-list-3-line",
            link: "/",
            title: "已报价"
        }, {
            icon: "ri-money-cny-circle-line",
            link: "/",
            title: "待付款"
        }, {
            icon: "ri-plane-line",
            link: "/",
            title: "待发货"
        }, {
            icon: "ri-takeaway-fill",
            link: "/",
            title: "待收货"
        }, {
            icon: "ri-calendar-schedule-line",
            link: "/",
            title: "待开票"
        }
    ];
    return <div className="py-5 my-3 border-y-[1px]">
        <div className="b-flex">
            <p className="text-base text-[#333]">我的订单</p>
            <ThemeLink href="/" className="text-[11px]">
                更多
                <i className="ri-arrow-right-s-line"></i>
            </ThemeLink>
        </div>
        <div className="grid grid-cols-3 gap-x-3 gap-y-2 my-4">
            {
                cardList.map((item, index) => {
                    return <ThemeLink href={item.link} key={index} className="c-flex flex-col">
                        <div className="w-[45px] h-[45px] bg-[#fff2e7] rounded-full c-flex">
                            <i className={clsx(item.icon, "text-main ri-xl")}></i>
                        </div>
                        <span className="text-sm mt-1">
                            {item.title}
                        </span>
                    </ThemeLink>;
                })
            }
        </div>
    </div>;
}

function RealTimeInfo({announce}: Props) {
    return <div>
        <div className="b-flex">
            <p className="italic font-bold text-[#212121]">恒配达<span className="text-main">公告</span></p>
            <ThemeLink href="/" className="text-[11px]">
                全部
                <i className="ri-arrow-right-s-line"></i>
            </ThemeLink>
        </div>
        <ul className="mt-1 space-y-1 pt-2 h-[156px] overflow-y-auto">
            {
                announce.map(item => {
                    return <li key={item.id} className="group">
                        <ThemeLink href={"/"} className="s-flex">
                            <Tag color="processing">公告</Tag>
                            <p className="line-clamp-1 text-[12px] text-themeP group-hover:text-main">{item.title}</p>
                        </ThemeLink>
                    </li>;
                })
            }
        </ul>
    </div>;
}

export default function InfoPanel(props: Props) {
    return <div className="bg-white w-full p-3 rounded-[10px] h-[573px] shadow border">
        <User/>
        <Order/>
        <RealTimeInfo announce={props.announce}/>
    </div>;
}
