type Props = {
    id: string;
    name: string;
    active: boolean;
    onClick: (id: string) => void;
}
export default function CategoryTag(props: Props) {
    return <div
        onClick={() => props.onClick(props.id)}
        className={`px-2.5 py-1 text-center cursor-pointer hover:bg-main hover:text-white duration-300 rounded-[2px] text-[12px]
            ${props.active ? "bg-main text-white" : "bg-mainBg text-gray-700"}
        `}>
        {props.name}
    </div>;
}
